import React from 'react';
import Modal from '../../../components/Modal';
import report from '../../../report/report.pdf';
const media = {
  secTitle: 'Louisville, KY',
  cityModal: true,
  type: 'video',
  video: {
    src: 'https://www.youtube.com/embed/F_aMY__JeoY',
  },
};

export default () => (
  <Modal closeTo="Been" media={media}>
    <p>
      Just across the Ohio River from Indiana, Louisville is Kentucky’s most
      populous city and shares a mix of Southern and Midwestern identity. Like
      many areas in the South, Louisville has a long history of racial
      segregation and inequity. As the home of boxer and activist Muhammad Ali
      and celebrated, mid-century monk and author Thomas Merton, Louisville also
      has a great legacy of peace, racial tolerance and social equality.
    </p>
    <p>
      The Louisville metro area is a growing and diversifying region. While it
      remains a majority white area (78 percent), it is attracting more and more
      people of color, with the Latino community being its fastest growing. Like
      many cities in the South, the unemployment rate for black workers (13.9
      percent) is more than twice the rate for whites (5.7 percent), while the
      median wage gap between whites and blacks & Latinos is 33 percent. Racial
      segregation persists in Louisville with different races and ethnicities
      largely concentrated together. In Louisville, we held discussions with
      residents and focus groups and roundtable discussions with community
      leaders and local civic organizations. We visited with Interfaith Paths to
      Peace to learn about their work promoting peace, human rights and justice
      through enhancing interfaith dialogue. At Backside Learning Center, we
      talked about the contributions of the immigrant community at Churchill
      Downs and learned about its adult education programming and after-school
      and summer services for youth. We visited the Muhammad Ali Center. At
      AMPED, we heard directly from young students on the west side about the
      challenges they face. We joined Mayor Greg Fischer and Rev. Dr. Alton B.
      Pollard III, president of the Louisville Presbyterian Theological
      Seminary, to discuss the history of racism that has shaped our society and
      the ways our country can reckon with its past. The conversation was a part
      of Mayor Fischer’s initiative Lean Into Louisville, a metro-wide equity
      building program. Finally, we conducted three focus groups with
      college-educated black and white residents and non-college-educated white
      Millennials. Learn more by reading the{' '}
      <a
        target="_blank"
        rel="noopener noreferrer"
        className="report-link"
        href={report}
      >
        full report.
      </a>
    </p>
  </Modal>
);
